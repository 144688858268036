import * as Sentry from '@sentry/browser'
import { UserData, LoggerService, Context } from './Logger'

export class LoggerServiceSentry implements LoggerService {
  constructor(init: { dsn: string; environment?: string; tracesSampleRate?: number }) {
    if (!Sentry.getClient()) {
      Sentry.init({
        environment: 'development',
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
            // stop PII going into replays
            maskAllText: true,
            blockAllMedia: true,
          }),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        ...init,
      })
    }
  }

  setUserData(u: UserData): void {
    Sentry.setUser({
      id: u.id,
    })
  }

  logError(e: Error | undefined, context?: Context): void {
    Sentry.captureException(e, context)
  }
}
